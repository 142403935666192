import {
    FLIPT_FLAGS,
    FLIPT_NAMESPACE,
    FLIPT_URL,
    IS_ON_PREMISE,
    USER,
} from "@/constants/constants";
import { FliptClient } from "@flipt-io/flipt";
import {
    BooleanEvaluationResponse,
    VariantEvaluationResponse,
} from "@flipt-io/flipt/dist/evaluation/models";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

type FlagType = "boolean" | "variant";

type GetFliptFlag = <T extends FlagType = "boolean">(props: {
    flagKey: string;
    flagType?: T;
}) => Promise<
    T extends "boolean" ? BooleanEvaluationResponse : VariantEvaluationResponse
>;

const getFliptFlag: GetFliptFlag = async <T>({
    flagKey = "",
    flagType = "boolean",
}) => {
    const client = new FliptClient({
        url: FLIPT_URL,
    });
    const userInfo: any = window.localStorage.getItem(USER);

    const response = await client.evaluation[flagType as FlagType]({
        flagKey,
        namespaceKey: FLIPT_NAMESPACE,
        entityId: nanoid(),
        context: {
            tenant_id: userInfo ? JSON.parse(userInfo).fraud_tenant_id : "",
        },
    });

    return response as T extends "boolean"
        ? BooleanEvaluationResponse
        : VariantEvaluationResponse;
};

const useGetFliptBooleanFlag = (flag: keyof typeof FLIPT_FLAGS) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const getFlags = async () => {
        if (IS_ON_PREMISE) {
            setIsEnabled(false);
            setIsLoading(false);
            return;
        }

        try {
            const evaluation = await getFliptFlag({
                flagKey: flag,
            });
            setIsEnabled(evaluation.enabled);
        } catch (error) {
            setIsEnabled(false);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getFlags();
    }, []);

    return { isEnabled, isLoading };
};

export { getFliptFlag, useGetFliptBooleanFlag };
