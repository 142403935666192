import type { FC, PropsWithChildren } from "react";
import { Header } from "./header/header";
import { useAuthStore } from "@/store/authStore";
import { useSideMenuStore } from "@/store/sideMenuStore";
import { useToast } from "@/components/toast";
import { addUnauthorizedInterceptor } from "@/services/api";

const Layout: FC<PropsWithChildren> = ({ children }) => {
    const { isGlobalLoader } = useAuthStore();
    const { isCollapsed } = useSideMenuStore();

    const toast: any = useToast();
    addUnauthorizedInterceptor(toast.pushError);

    return (
        <div className="page-wrapper">
            <Header />
            <main
                className={`content ${isCollapsed ? "content--expanded" : ""}`}
            >
                {children}
            </main>
            {isGlobalLoader && (
                <div className="dimmer">
                    <div className="global-loader">
                        <img
                            src="/loader.gif"
                            alt="Loader"
                            height="103"
                            width="151"
                        />
                        <span className="sr-only">Signing out...</span>
                    </div>
                </div>
            )}

            <div className="select-portal-content"></div>
        </div>
    );
};

export { Layout };
