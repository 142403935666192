import DashboardIcon from "../components/icons/dashboard";
import {
    DASHBOARD_ROUTE,
    EVENTS_ROUTE,
    CUSTOMERS_ROUTE,
    DEVICES_ROUTE,
    SETTINGS_ROUTE,
    LISTS_ROUTE,
} from "./routes";
import ShieldIcon from "../components/icons/shield";
import CustomersIcon from "../components/icons/customers-icon";
import DeviceIcon from "../components/icons/device";
import {
    CUSTOMERS,
    DASHBOARD,
    DEVICES,
    EVENTS,
    LISTS,
    SETTINGS,
} from "./constants";
import SettingsIcon from "../components/icons/settings";
import { ListsIcon } from "@/components/icons/lists";

export const navigation = [
    {
        label: DASHBOARD,
        url: DASHBOARD_ROUTE,
        icon: DashboardIcon,
    },
    {
        label: EVENTS,
        url: EVENTS_ROUTE,
        icon: ShieldIcon,
    },
    {
        label: CUSTOMERS,
        url: CUSTOMERS_ROUTE,
        icon: CustomersIcon,
    },
    {
        label: DEVICES,
        url: DEVICES_ROUTE,
        icon: DeviceIcon,
    },
    {
        label: LISTS,
        url: LISTS_ROUTE,
        icon: ListsIcon,
    },
    {
        label: SETTINGS,
        url: SETTINGS_ROUTE,
        icon: SettingsIcon,
    },
];
