import axios from "axios";
import { keycloak } from "./keycloak";
import Cookies from "js-cookie";

// Login & Settings apis
export const ME = "/user/me";
export const UPDATE_PASSWORD = "/user/me/pasword";
export const UPDATE_USER = "/user/me";

// Team Management apis
export const MEMBERS_LIST = "/v1/users";
export const ROLES_LIST = "/v1/users/roles";
export const CREATE_MEMBER = `/v1/users`;
export const ACTIVATE_DEACTIVATE_MEMBER = (user_id: number) =>
    `/v1/users/${user_id}/status`;
export const EDIT_MEMBER = (user_id: number) => `/v1/users/${user_id}`;

// Dashboard apis
export const GET_DASHBOARD_ANALYTICS = "/v1/dashboard";

// Activity Logs api
export const GET_AUDIT_LOGS = "/v1/tenants/audit-logs";

// Lists apis
export const LISTS = "/v1/lists";
export const RELATED_LISTS = ({ type, id }: { type: string; id: string }) =>
    `/v1/${type}/${id}/lists`;
export const LISTS_DETAILS = (id: string) => `/v1/lists/${id}`;
export const DEACTIVATE_LIST = (id: string) => `/v1/lists/${id}/deactivate`;
export const LIST_ITEMS = (id: string) => `/v1/lists/${id}/items`;
export const BATCH_UPLOAD_LIST_ITEMS = (id: string) =>
    `/v1/lists/${id}/items/import`;
export const MANAGE_COUNTRIES = (id: string) => `/v1/lists/${id}/batch
`;
export const DEACTIVATE_LIST_ITEM = ({
    listId,
    itemId,
}: {
    listId: string;
    itemId: string;
}) => `/v1/lists/${listId}/items/${itemId}/deactivate`;

// Event apis
export const EVENTS = "/v1/events";
export const REQUEST_DOWNLOAD_EVENTS = "/v1/events/reports";
export const DOWNLOAD_EVENT = (id: string) => `/v1/events/reports/${id}`;
export const GET_EVENT = (UUID = ":uuid") => `/v1/events/${UUID}`;

// Customer apis
export const CUSTOMERS_HISTORY = "/v1/customers";
export const GET_CUSTOMER = (id: string) => `/v1/customers/${id}`;
export const CUSTOMERS_SEARCH = "/v1/customers/search";
export const BLOCK_CUSTOMER = (id: string) =>
    `/v1/customers/${id}/add-to-blacklist`;
export const UNBLOCK_CUSTOMER = (id: string) =>
    `/v1/customers/${id}/remove-from-blacklist`;

// Device apis
export const DEVICES_HISTORY = "/v1/devices";
export const GET_DEVICE = (UUID = ":id") => `/v1/devices/${UUID}`;
export const BLOCK_DEVICE = (id: string) =>
    `/v1/devices/${id}/add-to-blacklist`;
export const UNBLOCK_DEVICE = (id: string) =>
    `/v1/devices/${id}/remove-from-blacklist`;

export const PUT_CUSTOMER_INFORMATION = "/customer-information";
export const GET_COUNTRY_LOOKUP = "/lookup/country";
export const DEVICE_SCORES = "/device-scores";
export const RULES = "/v1/rules";
export const UPDATE_RULE = (id: string) => `/v1/rules/${id}`;
export const API_KEYS_LIST = "/v1/tenants/api-keys";
export const CREATE_API_KEY = "/v1/tenants/api-keys";
export const THRESHOLD_API = "/v1/tenants/thresholds";

export const API = axios.create({
    baseURL: `${process.env.VITE_APP_API_URL}`,
    headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
    },
});

export const APIv2 = axios.create({
    baseURL: `${process.env.VITE_APP_API_URL_V2}`,
    headers: {
        accept: "application/json",
        "Content-Type": "application/json",
    },
});

export const APIv2_FormData = axios.create({
    baseURL: `${process.env.VITE_APP_API_URL_V2}`,
    headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
    },
});

export const addUnauthorizedInterceptor = (pushError: Function) => {
    const axiosInstances = [API, APIv2, APIv2_FormData];

    axiosInstances.forEach((axiosInstance) => {
        axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    pushError(
                        "Your session has expired. Redirecting to login.",
                        "Unauthorized",
                        5000,
                    );
                    setTimeout(() => {
                        keycloak.logout({
                            redirectUri: window.location.origin,
                        });
                    }, 5000);
                }
                return Promise.reject(error);
            },
        );

        axiosInstance.interceptors.request.use((config) => {
            const deviceFingerprintId = Cookies.get("device_fingerprint");
            if (deviceFingerprintId) {
                config.headers["device_fingerprint_id"] = deviceFingerprintId;
            }
            return config;
        });
    });
};
